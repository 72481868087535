import {getInstance} from "@/helpers/axios.service";
import {endPoint} from "@/helpers/Endpoint";

export const menuService = {
    get, gets, add, update, loadItem, addItem, statusChange
};

async function get(data = {}) {
    return await getInstance(endPoint.menuGet, data);
}

async function gets(data = {}) {
    return await getInstance(endPoint.menuGets, data);
}

async function add(request = {}) {
    return await getInstance(endPoint.menuAdd, request);
}

async function update(request = {}) {
    return await getInstance(endPoint.menuUpdate, request);
}

async function loadItem(request = {}) {
    return await getInstance(endPoint.menuLoadItem, request);
}

async function addItem(request = {}) {
    return await getInstance(endPoint.menuAddItem, request);
}

async function statusChange(request = {}) {
    return await getInstance(endPoint.menuStatusChange, request);
}
