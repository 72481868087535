<script>

import Layout from "../../../layouts/main.vue";
import PageHeader from "@/components/page-header";
import appConfig from "../../../../app.config";

import {menuService} from "@/helpers/blogservice/menu.service";

export default {
  page: {
    title: "Add/Change meme",
    meta: [{name: "description", content: appConfig.description}],
  },
  data() {
    return {
      title: "Menu",
      items: [
        {
          text: "Menu",
          href: "/",
        },
        {
          text: "Menu",
          active: true,
        },
      ],

      requestData: {
        id: 0,
        title: '',
        position: 1
      },

      menuItems: [],

      menuItem: {
        id: 0,
        title: '',
        path: '',
        menu_id: 0,
        parent_id: 0,
        priority: 1
      },

      listItems: []

    };
  },
  setup() {

  },
  mounted() {
    if (this.$route.params && this.$route.params.id) {
      this.requestData.id = this.$route.params.id;
      this.menuItem.menu_id = this.requestData.id;
      this.onLoadMenuItems();
    }
    this.onGet();
  },
  computed: {

  },
  components: {
    Layout,
    PageHeader
  },
  methods: {
    async onGet() {
      let response = await menuService.get({id: this.requestData.id});
      if (response?.data?.status) {
        const item = response.data.data;
        this.requestData.title = item?.model?.title ?? '';
        this.requestData.position = item?.model?.position ?? 1;
      }
    },
    async onSave() {
      if (this.requestData.id > 0) {
        let response = await menuService.update(this.requestData);
        if (response?.data?.status) {
          await this.$router.push({ name: 'menuManager' })
        }
      } else {
        let response = await menuService.add(this.requestData);
        if (response?.data?.status) {
          await this.$router.push({ name: 'menuManager' })
        }
      }
    },
    async onAddMenuItem() {
      let response = await menuService.addItem(this.menuItem);
      if (response?.data?.status) {
        this.menuItem = {
          id: 0,
          title: '',
          path: '',
          menu_id: this.requestData.id,
          parent_id: 0,
          priority: 1
        };
        document.querySelector('#modal-menu-change .btn-close').click();
        await this.onLoadMenuItems();
      }
    },
    async onLoadMenuItems() {
      let response = await menuService.loadItem({menu_id: this.requestData.id});
      if (response?.data?.status) {
        this.menuItems = response.data.data?.items ?? [];
        this.listItems = response.data.data?.treeItems ?? [];
      }
    },
    onAddNewItem(){
      this.menuItem = {
        id: 0,
        title: '',
        path: '',
        menu_id: this.requestData.id,
        parent_id: 0,
        priority: 1
      };
    },
    onEdit(menu) {
      document.getElementById('menuItemAddBtn').click();
      this.menuItem = {
        id: menu.id,
        title: menu.title,
        path: menu.path,
        menu_id: menu.menu_id,
        parent_id: menu.parent_id,
        priority: menu.priority
      };
    }
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items"/>
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-header">
            <div class="d-flex align-items-center">
              <h5 class="card-title mb-0 flex-grow-1">Add a menu</h5>
              <div class="flex-shrink-0 hstack gap-2">
                <button type="button" class="btn btn-info mr-5" @click="onSave">
                  <i class="las la-save"></i> Save
                </button>
                <router-link to="/setting/menu" class="btn btn-danger">
                  <i class="las la-times"></i> Cancel
                </router-link>
              </div>
            </div>
          </div>
          <!-- end card header -->

          <div class="card-body">

            <div class="row">
              <div class="col-6">

                <div class="row gy-4 mb-4">
                  <div class="col-xxl-12 col-md-12">
                    <label for="position" class="form-label">Position</label>
                    <select name="position" id="position" class="form-control" v-model="requestData.position">
                      <option value="1">Main</option>
                      <option value="2">Top</option>
                      <option value="3">Bottom</option>
                    </select>
                  </div>
                </div>

                <div class="row gy-4 mb-4">
                  <div class="col-xxl-12 col-md-12">
                    <label for="titleInput" class="form-label">Title</label>
                    <input
                        @input="event => requestData.title = event.target.value"
                        :value="requestData.title"
                        type="text"
                        class="form-control"
                        id="titleInput">
                  </div>
                </div>

              </div>
              <div class="col-6" v-if="requestData.id">

                <div class="row gy-4 mb-4">
                  <div class="col-xxl-12 col-md-12">
                    <label for="titleInput" class="form-label">Menu Items</label>
                    <div class="table-responsive">
                      <table class="table align-middle table-nowrap table-bordered table-hover">
                      <tbody>
                      <tr v-for="menu in menuItems" :key="menu.id">
                        <td>{{ menu.id }}</td>
                        <td>{{ menu.text }}</td>
                        <td>
                          <a href="javascript:void(0)" @click="onEdit(menu)">Edit</a>
                        </td>
                      </tr>
                      </tbody>
                      </table>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-12">
                    <b-button id="menuItemAddBtn" @click="onAddNewItem" v-b-modal.modal-menu-change>Add Item</b-button>
                  </div>
                </div>


              </div>
            </div>

          </div>
          <!-- end card-body -->

          <div class="card-footer text-muted">
            <div class="flex-shrink-0 hstack gap-2">
              <button type="button" class="btn btn-info mr-5" @click="onSave">
                <i class="las la-save"></i> Save
              </button>
              <router-link to="/setting/menu" class="btn btn-danger">
                <i class="las la-times"></i> Cancel
              </router-link>
            </div>
          </div>

        </div>
        <!-- end card -->
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->

    <div>
      <b-modal
          hide-footer
          clickToClose="false"
          id="modal-menu-change"
          ref="menuModal"
          title="Add Menu Item"
      >
        <div class="row gy-4 mb-4">
          <div class="col-xxl-12 col-md-12">
            <label for="titleInput" class="form-label">Root</label>
            <select name="menuitem" id="menuitem" class="form-control" v-model="menuItem.parent_id">
              <option value="0">Root</option>
              <option v-for="menuParentItem in listItems" :value="menuParentItem.id" :key="menuParentItem.id">{{ menuParentItem.text }}</option>
            </select>
          </div>
        </div>

        <div class="row gy-4 mb-4">
          <div class="col-xxl-12 col-md-12">
            <label for="titleInput" class="form-label">Title</label>
            <input
                @input="event => menuItem.title = event.target.value"
                :value="menuItem.title"
                type="text"
                class="form-control"
                id="titleItemInput">
          </div>
        </div>

        <div class="row gy-4 mb-4">
          <div class="col-xxl-12 col-md-12">
            <label for="titleInput" class="form-label">Path</label>
            <input
                @input="event => menuItem.path = event.target.value"
                :value="menuItem.path"
                type="text"
                class="form-control"
                id="pathItemInput">
          </div>
        </div>

        <div class="row gy-4 mb-4">
          <div class="col-xxl-12 col-md-12">
            <label for="titleInput" class="form-label">Priority</label>
            <input
                @input="event => menuItem.priority = event.target.value"
                :value="menuItem.priority"
                type="text"
                class="form-control"
                id="pathItemInput">
          </div>
        </div>

        <div class="row gy-4 mb-4">
          <div class="col-xxl-12 col-md-12">
            <button type="button" class="btn btn-info mr-5" @click="onAddMenuItem">
              <i class="las la-search"></i> Submit
            </button>
          </div>
        </div>

      </b-modal>
    </div>

  </Layout>
</template>
